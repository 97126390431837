<template>
    <div>
        <body id="body-pd" :class="showMenuInit">
            <header :class="onGetClass()" class="header body-pd" id="header">
                <div class="header_toggle show">
                    <i
                        class='fas fa-bars'
                        id="header-toggle"
                        data-automation="button-menu"
                    ></i>
                </div>
            </header>

            <VFMenu :class="onGetClass()" />

            <div class="height-auto mb-5">
                <router-view></router-view>
            </div>
        </body>
    </div>
</template>


<script>
    import { computed, onMounted } from 'vue';
    import { useRouter } from 'vue-router';
    import { VFMenu } from '@/components';

    export default {
        name: 'App',
        components: {
            VFMenu
        },
        setup() {
            const router = useRouter();

            const showMenuInit = computed(() => {
                return (router.currentRoute.value.name !== 'Login') ? 'body-pd' : '';
            });

            const onGetClass = () => {
                return (router.currentRoute.value.name === 'Login') ? 'd-none' : '';
            };

            onMounted(() => {
                const showNavbar = (toggleId, navId, bodyId, headerId) => {
                    const toggle = document.getElementById( toggleId ),
                        nav = document.getElementById( navId ),
                        bodypd = document.getElementById( bodyId ),
                        headerpd = document.getElementById( headerId );
        
                    if (toggle && nav && bodypd && headerpd) {
                        toggle.addEventListener('click', () => {
                            nav.classList.toggle( 'show-menu' );
                            toggle.classList.toggle( 'bx-x' );
                            bodypd.classList.toggle( 'body-pd' );
                            headerpd.classList.toggle( 'body-pd' );
                            const screenWidth = window.innerWidth;
        
                            document.getElementById( "image_logo" ).classList.add( "d-none" );
                            document.getElementById( "image_logo_reduced" ).classList.remove( "d-none" );
                            if ((screenWidth >= 768) && (nav.classList.contains( "show-menu" ))) {
                                document.getElementById( "image_logo" ).classList.remove( "d-none" );
                                document.getElementById( "image_logo_reduced" ).classList.add( "d-none" );
                            }
                        })
                    }
                };
        
                showNavbar('header-toggle', 'nav-bar', 'body-pd', 'header');
            });

            return {
                onGetClass,
                showMenuInit
            }
        }
    }
</script>