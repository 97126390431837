export const setConnections = ( state, connections ) => {
    state.connections = connections;
}

export const setLoading = ( state, value ) => {
    state.isLoading = value;
}

export const setConnection = ( state, connection ) => {
    state.connection = connection;
}

export const setTemplatesBack = ( state, templatesBack ) => {
    state.templatesBack = templatesBack;
}