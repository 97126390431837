<template>
    <div class="row">
        <div class="col-5 pe-0">
            <VFInputText
                vf-class="firstKeyValue"
                :title-element="keyName"
                name="nombre"
                id="nombre"
                v-model="nombre"
                placeholder="Nombre"
                :is-required="true"
                :class-below="errors.nombre ? 'text-danger' : ''"
                :text-below="errors.nombre"
                :disabled=disabled
            />
        </div>

        <div class="col-5 p-0">
            <VFInputText
                vf-class="secondKeyValue"
                :title-element="valueName"
                name="valor"
                id="valor"
                v-model="valor"
                placeholder="Valor"
                :is-required="true"
                :class-below="errors.valor ? 'text-danger' : ''"
                :text-below="errors.valor"
                :disabled=disabled
            />
        </div>

        <div class="col-2 text-center">
            <button
                class="btn mb-0 vf-button-success btn-sm"
                style="margin-top: 1.8rem;"
                @click="onAdd"
                data-automation="button-addkeyvalue"
            >
                <i class="fas fa-plus p-1" aria-hidden="true"></i>
            </button>
        </div>

        <div class="row">
            <div class="col-12">
                <ul class="list-group list-group-flush p-2">
                    <div >
                        <li class="list-group-item" v-for="(valor, key) in keyValues" :key="key">
                            {{ key }} : {{ valor }}
                            <a
                                style="cursor: pointer; color: black;"
                                @click="onRemove(key)"
                                :class="disabled ? 'disabled' : ''"
                                :data-automation="`button-removekeyvalue-${ key }`"
                            >
                                <i class="fas fa-close float-end p-2 delete-icon"></i>
                            </a>
                        </li>
                    </div>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import { VFInputText } from '@/components';
    import { useVFKeyValue } from '@/composables';

    export default {
        name: "VF-keyValue",
        components: {
            VFInputText
        },
        props: {
            keyName: {
                type: String,
                default: "Nombre"
            },
            valueName: {
                type: String,
                default: "Valor"
            },
            NotificationRemove: {
                type: Boolean,
                default: true
            },
            disabled: String,
        },
        setup(props, { emit }) {
            const {
                keyValues,
                nombre,
                valor,
                errors,
                onAdd,
                onRemove,
                onReset,
                onAddMassive,
            } = useVFKeyValue(props, emit);

            return {
                keyValues,
                nombre,
                valor,
                errors,
                onAdd,
                onRemove,
                onReset,
                onAddMassive
            };
        }
    };
</script>