/* eslint-disable */
const cfg = {
    getItemName () {
        return 'cfg_frontend'
    },
    async initialize () {
        return await fetch('/config.json')
            .then(response => response.json())
            .then(data => (localStorage.setItem(this.getItemName(), JSON.stringify(data))))
            .catch((error) => {
                console.error('initialize frontend configuration error', error)
            })
    },
    getConfiguration (key) {
        const config = localStorage.getItem(this.getItemName())
        const configJson = JSON.parse(config)
        if (!configJson) {
            console.error('frontend configuration error. Config not found')
            return null
        }
        return configJson[key]
    }
}

export default cfg