<template>
    <div v-if="show">
        <div class="" >
            <div class="row justify-content-center">
                <div class="spinner-border loading-icon text-primary" id=""></div>
            </div>

            <div class="row justify-content-center text-center">
                <span class="loading-text fw-bold mt-2">{{ text }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "vf-loading",
        props: {
            text: {
                type: String,
                default: 'Loading..'
            },
            show: {
                type: Boolean,
                default: false
            }
        },
    };
</script>