import { createRouter, createWebHistory } from 'vue-router';
import routeConnections from '@/modules/connections/router';

const Login = () => import('@/modules/auth/views/LoginView.vue');
const Home = () => import('@/views/HomeView.vue');

const routes = [
    {
        path: '/',
        name: "Login",
        component: Login,
        meta: {
            in_menu: false
        }
    },
    {
        path: '/home',
        name: "Inicio",
        component: Home,
        meta: {
            in_menu: true,
            icon: 'fas fa-home'
        }
    },
    ...routeConnections
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: "active",
});

export default router;