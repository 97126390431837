<template>
    <div class="card py-2 shadow" :class="vfClass" >
        <div class="card-header card-header-border">
            <slot name="header"/>
        </div>

        <div class="card-body">
            <slot name="body"/>
        </div>

        <div class="card-footer">
            <slot name="footer"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'vf-card',
        props: {
            vfClass: {
                type: String,
                default: "vf-card",
            }
        },
        methods: {
            getClass(classValue) {
                return `vf-${classValue}`;
            }
        }
    }
</script>