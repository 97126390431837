export const getTableColumns = ( state ) => {
    return state.tableColumns;
};

export const getConnections = ( state ) => {
    return state.connections;
};

export const getIsLoading = ( state ) => {
    return state.isLoading;
}

export const getConnection = ( state ) => {
    return state.connection;
};

export const getTemplatesBack = ( state ) => {
    return state.templatesBack;
};
