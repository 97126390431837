<template>
    <button
        :disabled="disabled"
        type="button"
        class="btn "
        @click="onHandleClick"
        :class="onGetClasses"
        data-bs-toggle="tooltip"
        data-bs-custom-class="custom-tooltip"
        :data-bs-placement="tooltipPlacement"
        :data-bs-title="tooltipTitle"
    >
        <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>

        <span v-else>
            <i v-if="icon" class="p-1" :class="onGetIcon"></i>
            <slot />
        </span>
    </button>
</template>

<script>
    import { onMounted } from 'vue';
    import { useVFButton } from '@/composables';

    export default {
        name: 'vf-button',
        props: {
            vfClass: {
                type: String,
                default: "vf-button",
            },
            size: {
                type: String,
                default: 'md'
            },
            disabled: {
                type: Boolean,
                default: false
            },
            loading: {
                type: Boolean,
                default: false
            },
            icon: String,
            iconDir: String,
            iconColor: {
                type: String,
                default: ''
            },
            tooltipTitle: {
                type: String,
                default: null,
            },
            tooltipPlacement: {
                type: String,
                default: 'bottom',
            }
        },
        setup(props, { emit }) {
            const {
                icon,
                iconDir,
                size,
                vfClass,
                onGetClasses,
                onGetIcon,
                onHandleClick,
                onInitTooltips
            } = useVFButton(props, emit);

            onMounted(() => {
                onInitTooltips();
            });

            return {
                // eslint-disable-next-line vue/no-dupe-keys
                icon,
                // eslint-disable-next-line vue/no-dupe-keys
                iconDir,
                // eslint-disable-next-line vue/no-dupe-keys
                size,
                // eslint-disable-next-line vue/no-dupe-keys
                vfClass,
                onGetClasses,
                onGetIcon,
                onHandleClick
            };
        }
    }
</script>