const formProps = {
    props: {
        size: {
            type: String,
            default: 'default',
        },
        valid: {
            type: Boolean,
            default: true,
        },
        vfClass: {
            type: String,
            default: '',
        },
        totalyClass:{ 
            type: String,
            default: '', 
        },
        titleClass: String,
        titleElement: String,
        name: String,
        id: String,
        type: String,
        placeholder: String,
        modelValue: String,
        isRequired: Boolean,
        disabled: String,
        icon: String,
        iconDir: String,
        textBelow: {
            type: String,
            default: '',
        },
        classBelow: {
            type: String,
            default: 'text-muted',
        },
        classIcon: {
            type: String,
            default: '',
        },
    },
};

export default formProps;