<template>
    <div class="l-navbar show-menu" id="nav-bar">
        <nav class="nav">
            <div>
                <a href="#" class="nav_logo">
                    <img
                        class="m-2"
                        id="image_logo"
                        src="@/assets/images/icon-verifarma-1.svg"
                        style="justify-items: center; grid-template-columns: auto;"
                        width="150"
                        height="32"
                        alt="Logo Verifarma"
                    >

                    <img
                        class="d-none"
                        id="image_logo_reduced"
                        src="@/assets/images/icon-verifarma-reduced.png"
                        style="justify-items: center; grid-template-columns: auto;"
                        width="25"
                        height="25"
                        alt="Logo Verifarma"
                    >
                </a>

                <div class="nav_list">
                    <a class="" v-for="(route, index) in rutasList" :class="getClass(route.meta.in_menu)" :key="index">
                        <router-link
                            class="nav_link"
                            v-if="route.meta.in_menu"
                            :to="route.path"
                            :data-automation="`link-route-${ route.name }`"
                        >
                            <i v-if="route.meta.icon" :class="route.meta.icon" class='nav_icon'></i>
                            <span class="nav_name">{{ route.name }}</span>
                        </router-link>
                    </a>
                </div>
            </div>
            <router-link
                class="nav_link"
                to="/"
                data-automation="button-exit"
            >
                <i class="fa-solid fa-right-from-bracket nav_icon"></i>
                <span class="nav_name">Salir</span>
            </router-link>
        </nav>
    </div>
</template>

<script>
    import { computed, ref } from 'vue';
    import { useRouter } from 'vue-router';

    export default {
        name: 'vf-menu',
        setup() {
            const router = useRouter();
            const rutasList = ref([]);

            const getRutasList = () => {
                rutasList.value = router.getRoutes();
            };

            const getClass = (in_menu) => {
                return !in_menu ? 'd-none' : '';
            };

            getRutasList();

            return {
                rutasList: computed(() => rutasList.value),
                getClass
            };
        }
    }
</script>