const ConnectionsList = () => import(/* webpackChunkName: "ConnectionsList" */ '@/modules/connections/views/list.vue');
const ConnectionsCreate = () => import(/* webpackChunkName: "ConnectionsCreate" */  '@/modules/connections/views/create.vue');
const ConnectionsEdit = () => import(/* webpackChunkName: "ConnectionsEdit" */ '@/modules/connections/views/update.vue');
const ConnectionsView = () => import(/* webpackChunkName: "ConnectionsView" */ '@/modules/connections/views/view.vue');


export default [
    {
        path: '/connections',
        name: "Conexiones",
        component: ConnectionsList,
        meta : {
            in_menu : true,
            icon : 'fas fa-project-diagram'
        },
    },
    {
        path: '/connections/create',
        name: "conexion-crear",
        component: ConnectionsCreate
    },
    {
        path: '/connections/edit/:id',
        name: "conexion-editar",
        component: ConnectionsEdit,
        props: ( route ) => {
            return {
                id: route.params.id
            }
        }
    },
    {
        path: '/connections/view/:id',
        name: "conexion-ver",
        component: ConnectionsView,
        props: ( route ) => {
            return {
                id: route.params.id
            }
        }
    }
]