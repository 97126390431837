export default () => ({
    tableColumns: [
        {
            label: 'Sender',
            field: 'from',
            type: 'string'
        },
        {
            label: 'Receiver',
            field: 'to',
            type: 'string'
        },
        {
            label: 'Action',
            field: 'action',
            type: 'string'
        },
        {
            label: 'Topic',
            field: 'topic',
            type: 'string'
        },
        {
            label: '',
            field: 'buttons',
            sortable: false
        }
    ],
    connections: [],
    isLoading: false,
    connection: {},
    templatesBack: [],
})