import { ref, computed } from 'vue';

export default function useVFSelect (props, emit) {

    const size = ref(props.size);
    const valid = ref(props.valid);
    const vfClass = ref(props.vfClass);
    const modelValue = ref(props.modelValue);

    const onGetClasses = computed(() => {
        let sizeValue = size.value ? `form-input-${size.value}` : '';
        let isValidValue = valid.value ? '' : 'vf-error';
        return `${sizeValue} ${isValidValue} ${vfClass.value}`;
    });

    const onUpdateModelValue = (event) => {
        modelValue.value = event.target.value;
        emit('update:modelValue', modelValue.value);
    };

    const onSelectedOption = (index, value, optionSelected) => {
        if (optionSelected == '' && index == 0 ) {
            return 'selected'
        }
        return value == optionSelected;
    }

    return {
        modelValue,
        onGetClasses,
        onSelectedOption,
        onUpdateModelValue,
    }
}