export default function useVFTable () {
    const basePrefix = 'table';

    const onAutomationTag = () => {
        setSearchInputAutomationTag();
        setHeaderAutomationTag();
        setEntriesPerPageSelectAutomationTag();
        setPaginatorAutomationTag();
        setNavigationButtonsAutomationTag();
    }

    const setSearchInputAutomationTag = () => {
        const inputTableSearch = document.querySelector('[id*=vgt-search]');
        if ( inputTableSearch ) {
            inputTableSearch.setAttribute('data-automation', `${ basePrefix }-search`);
        }
    }

    const setHeaderAutomationTag = () => {
        const headersTable = document.querySelectorAll('.vgt-left-align.sortable');
        headersTable.forEach( header => {
            const headerText = header.querySelector('span').textContent.trim();
            header.setAttribute('data-automation', `${ basePrefix }-header-${ headerText }`);
        });
    }

    const setEntriesPerPageSelectAutomationTag = () => {
        const entriesPerPageTableSelect = document.querySelector('[id*=vgt-select-rpp]');
        if ( entriesPerPageTableSelect ) {
            entriesPerPageTableSelect.setAttribute('data-automation', `${ basePrefix }-select-entries`);
            const paginatorOptionsTable = entriesPerPageTableSelect.querySelectorAll('option');
            if ( paginatorOptionsTable ) {
                paginatorOptionsTable.forEach( option => {
                    const optiontext = option.textContent.trim();
                    option.setAttribute('data-automation', `${ basePrefix }-select-option-${ optiontext }`);
                });
            }
        }
    }

    const setPaginatorAutomationTag = () => {
        const paginatorTable = document.querySelector('[id*=vgt-page-input]');
        if ( paginatorTable ) {
            paginatorTable.setAttribute('data-automation', `${ basePrefix }-paginator`);
        }
    }

    const setNavigationButtonsAutomationTag = () => {
        const navigationButtonsTable = document.querySelectorAll('.footer__navigation__page-btn');
        navigationButtonsTable.forEach( button => {
            const buttonText = button.querySelector('span:not([aria-hidden="true"])').textContent.trim();
            button.setAttribute('data-automation', `${ basePrefix }-navigation-${ buttonText }`);
        });
    }

    const onGetSearchOptions = () => {
        return {
            enabled: true,
            placeholder: 'Buscar configuración',
        };
    }

    const onGetPaginationOptions = () => {
        return {
            enabled: true,
            mode: 'pages',
            perPage: 10,
            nextLabel: 'Siguiente',
            prevLabel: 'Anterior',
            rowsPerPageLabel: 'Registros por página',
            pageLabel: 'Pág.',
            ofLabel: 'de',
        }
    }

    return {
        onAutomationTag,
        onGetPaginationOptions,
        onGetSearchOptions
    }
}