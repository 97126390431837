import cfg from "@/service/Configurations";

export const shouldProcessProvider = (templates, provider) => {
    return (
        typeof templates === 'object'
        && provider !== 'default'
        && provider !== 'components'
    );
};

export const processTemplates = (templates, basePath, provider) => {
    const templates_aux = [];

    for (const keyTemplates in templates) {
        const elementTemplate = templates[ keyTemplates ];

        if (typeof elementTemplate === 'object') {
            templates_aux.concat(
                searchTemplate(elementTemplate, provider, templates_aux, keyTemplates + '/')
            );
        } else if (typeof elementTemplate === 'string') {
            templates_aux.push(
                `${ basePath }/${ provider }/${ elementTemplate.replaceAll('.blade.php', '') }`
            );
        }
    }

    return templates_aux;
};

export const searchTemplate = (templates, provider, allTemplates, keyFolder = '') => {
    for (const keyTemplates in templates) {
        if (keyTemplates == "components") {
            continue;
        }
        let elementTemplate = templates[ keyTemplates ];
        if (typeof elementTemplate == "string") {
            allTemplates.push(`${ cfg.getConfiguration('VUE_APP_REPORT_SERVER_BASE_PATH') }/${ provider }/${ keyFolder }${ elementTemplate.replaceAll(".blade.php", "")}`)
        }
    }
    return allTemplates;
}