<template>
    <div class="form-switch switchToggle">
        <label class="form-check-label">
            {{ titleElement }}
        </label>

        <input
            class="form-check-input"
            type="checkbox"
            :id="id"
            :name="name"
            :disabled="disabled"
            :checked="checked"
            @input="onUpdateModelValue"
            :data-automation="`checkbox-${ id }`"
        >

        <label class="labelDecision" :class="classLabel" :for="id"></label>
    </div>
</template>

<script>
    import { computed } from 'vue';
    import { formProps } from '@/mixins';
    import { useVFInput } from '@/composables';

    export default {
        name: "VF-switch",
        props: {
            checked: {
                type: Boolean,
                default: false
            }
        },
        mixins: [
            formProps
        ],
        setup(props, { emit }) {
            const {
                modelValue,
                onUpdateModelValue
            } = useVFInput(props, emit);

            const selected = computed({
                get() {
                    return modelValue.value;
                },
                set(val) {
                    emit('update:modelValue', val);
                }
            });

            const classLabel = computed(() => {
                return props.disabled ? 'disabled' : '';
            });

            return {
                classLabel,
                selected,
                onUpdateModelValue
            };
        }
    };
</script>
