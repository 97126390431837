import { reportServerApi, routingRuleApi } from "@/api";
import cfg from "@/service/Configurations";
import { processTemplates, shouldProcessProvider } from "../helpers/templates";

/**
 *  Obtener las conexiones
 */
export const getConnections = async ({ commit }) => {
    try {
        commit('setLoading', true);

        const { data } = await routingRuleApi.get('/api/egresses/routing_rules');

        const connections = data.map( connection => {
            const { _id, from, to, action, topic  } = connection;
            return { _id, from, to, action, topic };
        });

        commit('setConnections', connections );
        commit('setLoading', false);

        return {
            ok: true
        }
    } catch (error) {
        commit('setLoading', false);

        return {
            ok: false,
            message: 'No se pudieron obtener las conexiones.'
        }
    }
}

/**
 *  Obtener una conexión
 */
export const getConnection = async ({ commit }, id) => {
    try {
        commit('setLoading', true);

        const { data } = await routingRuleApi.get(`/api/egresses/routing_rules/${id}`);

        commit('setLoading', false);

        commit('setConnection', data );

        return {
            ok: true
        }
    } catch (error) {
        commit('setLoading', false);

        return {
            ok: false,
            message: `No se pudo obtener la conexión ${id}`
        }
    }
}

/**
 *  Crear una conexión
 */
export const createConnection = async ({ commit }, dataForm) => {
    try {
        commit('setLoading', true);

        const { data } = await routingRuleApi.post('/api/egresses/routing_rules', dataForm);

        commit('setLoading', false);

        return {
            ok: true,
            message:" Se creo la conexión correctamente ",
            data
        }
    } catch (error) {
        let message = 'No se pudo crear la conexión, reintente luego...';
        if (error.response?.data.message) {
            message = error.response.data.message;
        }
        commit('setLoading', false);
        return {
            ok: false,
            message
        }
    }
}

/**
 *  Actualizar una conexión
 */
export const updateConnection = async ({ commit }, dataUpdate) => {
    try {
        commit('setLoading', true);
        const {id, dataForm} = dataUpdate;
        const { data } = await routingRuleApi.put(`/api/egresses/routing_rules/${id}`, dataForm);

        commit('setLoading', false);

        return {
            ok: true,
            message:" Se actualizó la conexión correctamente ",
            data
        }
    } catch (error) {
        let message = 'No se pudo actualizar la conexión, reintente luego...';
        if (error.response?.data.message) {
            message = error.response.data.message;
        }
        commit('setLoading', false);
        return {
            ok: false,
            message
        }
    }
}

/**
 *  Eliminar una conexión
 */
export const deleteConnection = async ({ commit }, id) => {
    try {
        commit('setLoading', true);
        commit('setConnections', [] );
        const { data } = await routingRuleApi.delete(`/api/egresses/routing_rules/${id}`);

        commit('setLoading', false);
        return {
            ok: true,
            message:" Se eliminó la conexión correctamente ",
            data
        }
    } catch (error) {
        let message = 'No se pudo eliminar la conexión, reintente luego...';
        if (error.response?.data.message) {
            message = error.response.data.message;
        }
        commit('setLoading', false);
        return {
            ok: false,
            message
        }
    }
}

/**
 *  Obtener los templates disponibles
 */
export const getTemplates = async ({ commit }) => {
    try {
        const allTemplates = [];

        const { data } = await reportServerApi.get('/api/report_templates');

        const basePath = cfg.getConfiguration('VUE_APP_REPORT_SERVER_BASE_PATH');

        if (!data[ basePath ]) {
            throw new Error(`No se pudieron obtener los templates para ${ basePath }.`);
        }

        for (const provider in data[ basePath ]) {
            const templates = data[ basePath ][ provider ];

            if (shouldProcessProvider(templates, provider)) {
                const templates_aux = processTemplates(templates, basePath, provider);
                const datatemplate = {
                    provider,
                    templates: templates_aux,
                };
                allTemplates.push(datatemplate);
            }
        }

        commit('setTemplatesBack', allTemplates);

        return {
            okTemplates: true,
        };
    } catch (error) {
        return {
            okTemplates: false,
            messageTemplates: 'No se pudieron obtener los templates.',
        };
    }
};
