const selectProps = {
    props: {
        size: {
            type: String,
            default: "default",
        },
        valid: {
            type: Boolean,
            default: true,
        },
        titleClass: String,
        titleSelect: String,
        name: String,
        id: String,
        placeholder: String,
        modelValue: String,
        isRequired: Boolean,
        disabled: String,
        options: Object,
        optionSelected: {
            type: String,
            default: ''
        },
        textBelow: String,
        classBelow: {
            type: String,
            default: 'text-muted'
        },
    },
};

export default selectProps;